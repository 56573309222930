<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UserModal from '@/views/dashboard/components/modals/UserModal';

export default {
    name: 'Shareholders',

    components: { UserModal },

    data: () => ({
        loading: false,
        search_shares: '',
        search: null,
        open_dialog: false,

        filter: {
            mail: '',
            sales_name: '',
            total_amount: '',
            count_shares: '',
            shareholder_shares: '',
        },
    }),

    watch: {
        search(str) {
            if (str) {
                this.filter.shareholder_shares = this.shareholder_shares.filter(
                    item => item.includes(str.toLowerCase()),
                );
            } else {
                this.filter.shareholder_shares = '';
            }
        },
    },

    methods: {
        ...mapActions('shareholders', [
            'fetchShareholders',
            'fetchShareholderShares',
            'getAllShareholdersExcel',
        ]),
        ...mapActions('user_modal', ['fetchUserInfo']),
        ...mapMutations('shareholders', ['setShareholderShares']),

        async downloadShareholderExcel() {
            await this.getAllShareholdersExcel().then(res => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'all-shareholders.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        },

        async getShareholderShares(id) {
            await this.fetchShareholderShares(id);
            this.open_dialog = true;
        },

        closeDialog() {
            this.setShareholderShares([]);
            this.search = '';
            this.filter.shareholder_shares = '';
            this.open_dialog = false;
        },

        numberWithSpaces(num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
    },

    computed: {
        ...mapGetters('shareholders', {
            shareholders: 'getShareholders',
            shareholder_shares: 'getShareholderShares',
        }),

        headers() {
            return [
                {
                    text: 'User Email',
                    value: 'mail',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.mail.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Sales Manager',
                    value: 'sales_name',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.sales_name.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Amount (€)',
                    value: 'total_amount',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.total_amount.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Quantity Shares',
                    value: 'count_shares',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.count_shares.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Details',
                    value: 'details',
                    align: 'center',
                },
            ];
        },
    },

    async created() {
        this.loading = true;
        await this.fetchShareholders();
        this.loading = false;
    },
};
</script>

<style scoped>
.startup__actions {
    top: -60px;
    right: 5%;
    z-index: 1;
    position: absolute;
}

.btnDownload {
    cursor: pointer;
}

.fixed-bar {
    width: 770px;
    position: fixed;
    z-index: 2;
}
</style>

<template>
    <div>
        <div class="startup__actions">
            <v-btn
                class="btnDownload"
                color="#47a4db"
                @click="downloadShareholderExcel"
            >
                Download Excel
            </v-btn>
        </div>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            dense
            :headers="headers"
            :items="shareholders"
            :items-per-page="10"
            :footer-props="{
                'items-per-page-options': [10, 20, 40, 60, 80, 100],
            }"
        >
            <template v-slot:[`item.mail`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            v-on="on"
                            v-bind="attrs"
                            class="blue--text"
                            @click.prevent="fetchUserInfo(item.user_id)"
                            >{{ item.mail }}</a
                        >
                    </template>
                    <span>User id {{ item.user_id }}</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.sales_name`]="{ item }">
                {{ item.sales_name ? item.sales_name : '-' }}
            </template>

            <template v-slot:[`item.total_amount`]="{ item }">
                {{ numberWithSpaces(item.total_amount.toFixed(2)) }}
            </template>

            <template v-slot:[`item.count_shares`]="{ item }">
                {{ numberWithSpaces(item.count_shares) }}
            </template>

            <template v-slot:[`item.details`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-on="on"
                            v-bind="attrs"
                            icon
                            text
                            @click.prevent="getShareholderShares(item.user_id)"
                        >
                            <v-icon size="30">mdi-information-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Details</span>
                </v-tooltip>
            </template>

            <template v-slot:[`body.prepend`]>
                <tr>
                    <td>
                        <v-text-field v-model="filter.mail"></v-text-field>
                    </td>

                    <td>
                        <v-text-field
                            v-model="filter.sales_name"
                        ></v-text-field>
                    </td>

                    <td>
                        <v-text-field
                            v-model="filter.total_amount"
                        ></v-text-field>
                    </td>

                    <td>
                        <v-text-field
                            v-model="filter.count_shares"
                        ></v-text-field>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog v-model="open_dialog" persistent max-width="770">
            <v-card style="font-size: 16px;" height="500px">
                <v-toolbar flat dark color="primary" class="fixed-bar">
                    <v-toolbar-title>Shareholder Shares</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-autocomplete
                        v-model="search_shares"
                        :items="shareholder_shares"
                        :search-input.sync="search"
                        class="mx-4"
                        flat
                        clearable
                        hide-no-data
                        hide-details
                        label="Search by share number"
                        solo-inverted
                    ></v-autocomplete>
                    <v-btn icon dark @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-data-table
                    style="padding:80px 20px 20px"
                    disable-pagination
                    hide-default-footer
                    :items="
                        (filter.shareholder_shares &&
                            filter.shareholder_shares) ||
                            shareholder_shares
                    "
                >
                    <template v-slot:[`item`]="{ item }">
                        {{ item + ', ' }}
                    </template>
                </v-data-table>
            </v-card>
        </v-dialog>
        <user-modal />
    </div>
</template>
