<script>
import { mapActions, mapGetters } from 'vuex';
import config from '@/vue.config';
import USER_STATUS from '@/constants/user_statuses';
import helper from '@/plugins/helper';

export default {
    name: 'UserModal',

    data: () => ({
        USER_STATUS: USER_STATUS,
        dialog_save_confirm: false,
        dialog_approve_confirm: false,
        dialog_decline_confirm: false,
        dialog_history: false,
        history_item: null,
        errors: {},
        menu: false,
        form: {},
        tab: null,
        photo: {
            url: '',
            obj: '',
        },
        verification_photo: {
            url: '',
            obj: '',
        },
        previewVisible: false,
        previewImage: '',
    }),
    computed: {
        ...mapGetters('user_modal', {
            modalShow: 'getModalShow',
            userInfo: 'getUserInfo',
            userInfoErrors: 'getUserInfoErrors',
        }),
        ...mapGetters('users', {
            statuses: 'getUserStatuses',
            countries: 'getUserCountries',
            identificationTypes: 'getUserIdentificationTypes',
        }),

        roleAdapter() {
            return this.form.role === 'entrepreneur'
                ? 'founder'
                : this.form.role;
        },
    },

    async created() {
        await this.fetchUserStatuses();
        await this.fetchUserCountries();
        await this.fetchIdentificationTypes();
    },

    methods: {
        ...mapActions('user_modal', [
            'closeModal',
            'fetchEditProfile',
            'fetchApproveUser',
            'fetchDeclineUser',
            'resetFinances',
        ]),

        ...mapActions('users', [
            'fetchUsers',
            'fetchUserStatuses',
            'fetchUserCountries',
            'fetchIdentificationTypes',
        ]),
        prepareData() {
            this.form = { ...this.userInfo };
            this.form.status = parseInt(this.userInfo.status);
            this.form.expiration_date = this.userInfo.identification_type
                ? this.userInfo.expiration_date_human ?? 'endless'
                : '';
            this.form.endless = this.form.expiration_date === 'endless';
            this.form.photo = this.form.photo ?? config.no_image;
            this.form.verification_photo =
                this.form.verification_photo ?? config.no_image;
            this.form.first_name = this.form.first_name ?? '';
            this.form.last_name = this.form.last_name ?? '';
            this.form.middle_name = this.form.middle_name ?? '';
            this.form.username = this.form.username ?? '';
            this.form.identification_type = this.form.identification_type ?? '';
            this.form.citizenship = this.form.citizenship ?? '';
            this.form.state = this.form.state ?? '';
            this.form.zip_code = this.form.zip_code ?? '';
            this.form.city = this.form.city ?? '';
            this.form.address = this.form.address ?? '';
            this.form.phone = this.form.phone ?? '';
            this.form.decline_text = this.form.decline_text ?? '';
        },
        toggleResetFinances() {
            let response = this.resetFinances({
                user_id: this.form.user_id,
            });
            response.then(data => {
                if (data) this.$alertify.success('Successfully reset finances');
                else this.$alertify.error('Update error');
            });
            this.dialog_decline_confirm = false;
        },

        getFormData() {
            return {
                scenario: 'admin',
                admin: 1,
                user_id: this.form.user_id,
                first_name: this.form.first_name,
                last_name: this.form.last_name,
                username: this.form.username,
                middle_name: this.form.middle_name,
                identification_type: this.form.identification_type,
                expiration_date:
                    this.form.expiration_date === 'Endless'
                        ? ''
                        : this.form.expiration_date,
                birth_date: this.form.birth_date,
                citizenship: this.form.citizenship,
                state: this.form.state,
                zip_code: this.form.zip_code,
                city: this.form.city,
                address: this.form.address,
                photo: this.photo.obj ?? null,
                verification_photo:
                    this.verification_photo.obj || this.form.verification_photo,
                mail_verification: this.form.mail_verification,
                phone_verification: this.form.phone_verification,
                risks_confirm: this.form.risks_confirm,
                two_factor_auth: this.form.two_factor_auth,
                phone: this.form.phone,
            };
        },

        async saveProfile() {
            const response = await this.fetchEditProfile(this.getFormData());
            if (response) {
                this.$alertify.error('Unsuccessfully saved profile');
            } else {
                this.$alertify.success('Successfully saved profile');
            }
        },

        editProfile() {
            if (this.approveUser())
                this.$alertify.success('Successfully updated and approved');
            this.dialog_save_confirm = false;
            this.dialog_approve_confirm = false;
        },

        resetErrors() {
            this.errors = {};
            this.errors = { ...this.errors };
        },

        handlePhotoChange(info) {
            helper.getBase64(info.file.originFileObj, imageUrl => {
                this.photo.url = imageUrl;
                this.photo.obj = info.file.originFileObj;
            });
        },

        handleVerificationPhotoChange(info) {
            helper.getBase64(info.file.originFileObj, imageUrl => {
                this.verification_photo.url = imageUrl;
                this.verification_photo.obj = info.file.originFileObj;
            });
        },

        handleCancel() {
            this.previewVisible = false;
        },

        async handlePreviewPhoto() {
            if (this.photo.url) this.previewImage = this.photo.url;
            else this.previewImage = this.form.photo;
            this.previewVisible = true;
        },

        async handlePreviewVerificationPhoto() {
            if (this.verification_photo.url)
                this.previewImage = this.verification_photo.url;
            else this.previewImage = this.form.verification_photo;
            this.previewVisible = true;
        },

        approveUser() {
            return this.fetchApproveUser({
                user_id: this.form.user_id,
                type: 1,
            }).then(data => {
                return data;
            });
        },

        declineUser() {
            if (this.form.decline_text) {
                let response = this.fetchDeclineUser({
                    user_id: this.form.user_id,
                    type: 2,
                    decline_text: this.form.decline_text,
                });
                response.then(data => {
                    if (data) this.$alertify.success('Successfully declined');
                    else this.$alertify.error('Update error');
                });
                this.dialog_decline_confirm = false;
            } else {
                this.$alertify.error('Fill decline text');
            }
            this.userInfo.decline_text = this.form.decline_text;
        },

        modalClose() {
            this.tab = null;
            this.resetErrors();
            this.resetPhotos();
            this.closeModal();
        },

        resetPhotos() {
            this.photo.url = '';
            this.photo.obj = '';
            this.verification_photo.url = '';
            this.verification_photo.obj = '';
        },

        getHistoryModel(item) {
            this.history_item = item;
            this.dialog_history = true;
        },

        getHistoryImage(image) {
            if (!image) image = config.no_image;
            return image;
        },
    },

    filters: {
        getLabel: function(field) {
            if (field === 'username') return 'nickname';
            return field
                .replace('_', ' ')
                .replace('_', ' ')
                .replace('_', ' ');
        },
    },

    watch: {
        userInfo() {
            if (this.userInfo.history)
                this.userInfo.history = Object.values(
                    this.userInfo.history,
                ).reverse();
            this.prepareData();
        },
        userInfoErrors() {
            if (Object.keys(this.userInfoErrors).length > 0) {
                for (let [key, value] of Object.entries(this.userInfoErrors)) {
                    this.errors[key] = value[0];
                }
                this.errors = { ...this.errors };
            } else {
                this.resetErrors();
            }
        },
    },
};
</script>

<template>
    <v-row justify="space-around">
        <v-dialog
            width="80%"
            v-model="modalShow"
            transition="dialog-transition"
            @click:outside="modalClose"
            @keydown.esc="modalClose"
        >
            <v-card class="mx-auto">
                <v-toolbar color="primary" dark>
                    <v-tabs color="white accent-4" v-model="tab" left>
                        <v-tab>
                            Personal info
                        </v-tab>

                        <v-spacer></v-spacer>
                        <span
                            style="font-size: 21px; margin-top: 17px; margin-right: 83px;"
                            ><span>USER ID: {{ form.user_id }}</span></span
                        >
                        <v-spacer></v-spacer>
                    </v-tabs>
                    <div>
                        <v-btn text @click="modalClose">Close</v-btn>
                    </div>
                </v-toolbar>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-form>
                            <v-container style="max-width: 100%">
                                <v-row>
                                    <v-col cols="2">
                                        <div style="margin-top: 15px">
                                            <p class="mb-0">Profile Photo</p>
                                            <span
                                                @click="handlePreviewPhoto"
                                                class="ant-upload ant-upload-select ant-upload-select-picture-card"
                                            >
                                                <img
                                                    style="width: 100px"
                                                    class="img-fluid"
                                                    v-if="photo.url"
                                                    :src="photo.url"
                                                    alt="photo back"
                                                />
                                                <img
                                                    style="width: 100px"
                                                    class="img-fluid"
                                                    v-else-if="form.photo"
                                                    :src="form.photo"
                                                    alt="photo front"
                                                />
                                            </span>
                                            <a-upload
                                                class="avatar-uploader"
                                                :show-upload-list="false"
                                                @change="handlePhotoChange"
                                                :custom-request="() => {}"
                                            >
                                                <a class="blue--text">
                                                    Change profile photo
                                                </a>
                                            </a-upload>
                                        </div>
                                        <div style="margin-top: 35px;">
                                            <p class="mb-0">Document Photo</p>
                                            <span
                                                @click="
                                                    handlePreviewVerificationPhoto
                                                "
                                                class="ant-upload ant-upload-select ant-upload-select-picture-card"
                                            >
                                                <img
                                                    style="width: 100px"
                                                    class="img-fluid"
                                                    v-if="
                                                        verification_photo.url
                                                    "
                                                    :src="
                                                        verification_photo.url
                                                    "
                                                    alt="photo back"
                                                />
                                                <img
                                                    style="width: 100px"
                                                    class="img-fluid"
                                                    v-else-if="
                                                        form.verification_photo
                                                    "
                                                    :src="
                                                        form.verification_photo
                                                    "
                                                    alt="photo front"
                                                />
                                            </span>
                                            <a-upload
                                                class="avatar-uploader"
                                                :show-upload-list="false"
                                                @change="
                                                    handleVerificationPhotoChange
                                                "
                                                :custom-request="() => {}"
                                            >
                                                <a class="blue--text">
                                                    Change document photo
                                                </a>
                                            </a-upload>
                                        </div>
                                    </v-col>
                                    <v-col style="margin-top: 15px;" cols="8">
                                        <v-row style="margin-top: 10px">
                                            <v-col cols="4">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    label="Mail"
                                                    v-model="form.mail"
                                                    readonly
                                                    filled
                                                >
                                                </v-text-field>
                                                <div
                                                    class="checkbox_under_field"
                                                    :class="[
                                                        userInfo.mail_verification
                                                            ? 'greenMailColor'
                                                            : 'redMailColor',
                                                    ]"
                                                >
                                                    <v-checkbox
                                                        dense
                                                        v-model="
                                                            form.mail_verification
                                                        "
                                                        label="Mail verification"
                                                    ></v-checkbox>
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    label="Role"
                                                    v-model="roleAdapter"
                                                    readonly
                                                    filled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-select
                                                    outlined
                                                    dense
                                                    label="Status"
                                                    v-model="form.status"
                                                    :items="statuses"
                                                    item-text="name"
                                                    item-value="id"
                                                    readonly
                                                    filled
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row style="margin-top: 10px">
                                            <v-col cols="3">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    :error-messages="
                                                        errors.first_name
                                                    "
                                                    label="First Name"
                                                    v-model="form.first_name"
                                                    class="first_name"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    :error-messages="
                                                        errors.last_name
                                                    "
                                                    label="Last Name"
                                                    v-model="form.last_name"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            :error-messages="
                                                                errors.birth_date
                                                            "
                                                            label="Birth date"
                                                            v-model="
                                                                form.birth_date
                                                            "
                                                            append-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="
                                                            form.birth_date
                                                        "
                                                        no-title
                                                    >
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="
                                                                menu = false
                                                            "
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="
                                                                menu = false
                                                            "
                                                        >
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row style="margin-top: 33px">
                                            <v-col cols="2">
                                                <v-select
                                                    outlined
                                                    dense
                                                    :error-messages="
                                                        errors.citizenship
                                                    "
                                                    label="Country"
                                                    v-model="form.citizenship"
                                                    :items="countries"
                                                    item-value="name"
                                                    item-text="name"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    :error-messages="
                                                        errors.city
                                                    "
                                                    label="City"
                                                    v-model="form.city"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    :error-messages="
                                                        errors.address
                                                    "
                                                    label="Address"
                                                    v-model="form.address"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    :error-messages="
                                                        errors.zip_code
                                                    "
                                                    label="Zip code"
                                                    v-model="form.zip_code"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row style="margin-top: 10px">
                                            <v-col cols="3">
                                                <v-select
                                                    outlined
                                                    dense
                                                    :error-messages="
                                                        errors.identification_type
                                                    "
                                                    label="Identification type"
                                                    v-model="
                                                        form.identification_type
                                                    "
                                                    :items="identificationTypes"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="5">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    :error-messages="
                                                        errors.phone
                                                    "
                                                    label="Phone number"
                                                    v-model.trim="form.phone"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row style="margin-top: 10px">
                                            <v-col cols="4">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    label="Register date"
                                                    v-model="
                                                        form.created_at_human
                                                    "
                                                    readonly
                                                    filled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-checkbox
                                                    dense
                                                    v-model="form.risks_confirm"
                                                    label="Risks confirm"
                                                    style="margin-top: 6px"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-checkbox
                                                    dense
                                                    v-model="
                                                        form.two_factor_auth
                                                    "
                                                    label="Two factor auth"
                                                    style="margin-top: 6px"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="2"> </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="2">
                                        <div
                                            style="text-align: center; padding-top: 15px"
                                        >
                                            <v-dialog
                                                v-model="dialog_approve_confirm"
                                                persistent
                                                max-width="290"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-btn
                                                        color="success"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        Approve
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title
                                                        class="headline"
                                                    >
                                                        Approve?
                                                    </v-card-title>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="red darken-1"
                                                            text
                                                            @click="
                                                                dialog_approve_confirm = false
                                                            "
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            color="green darken-1"
                                                            text
                                                            @click="
                                                                editProfile(
                                                                    true,
                                                                )
                                                            "
                                                        >
                                                            Yes
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                        <div
                                            v-show="
                                                form.status !==
                                                    USER_STATUS.DECLINE &&
                                                    form.status !==
                                                        USER_STATUS.REGISTERED
                                            "
                                            style="text-align: center; padding-top: 15px"
                                        >
                                            <v-dialog
                                                v-model="dialog_decline_confirm"
                                                persistent
                                                max-width="600"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-btn
                                                        color="error"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        Decline
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title
                                                        class="headline"
                                                    >
                                                        Decline user?
                                                    </v-card-title>
                                                    <div>
                                                        <v-textarea
                                                            outlined
                                                            dense
                                                            label="Declined text"
                                                            v-model.trim="
                                                                form.decline_text
                                                            "
                                                            style="padding: 9px;"
                                                        ></v-textarea>
                                                    </div>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="red darken-1"
                                                            text
                                                            @click="
                                                                dialog_decline_confirm = false
                                                            "
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            color="green darken-1"
                                                            text
                                                            @click="declineUser"
                                                        >
                                                            Yes
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                        <div
                                            v-show="
                                                (form.status ===
                                                    USER_STATUS.DECLINE ||
                                                    form.status ===
                                                        USER_STATUS.REPEAT_PENDING) &&
                                                    userInfo.decline_text
                                            "
                                            style="padding-top: 15px"
                                        >
                                            <v-textarea
                                                outlined
                                                dense
                                                label="Declined text"
                                                v-model.trim="form.decline_text"
                                                v-show="form.decline_text"
                                                readonly
                                                filled
                                            ></v-textarea>
                                        </div>
                                        <div
                                            style="text-align: center; padding-top: 15px"
                                        >
                                            <v-btn
                                                color="blue"
                                                @click="saveProfile"
                                            >
                                                Save
                                            </v-btn>
                                        </div>
                                        <div
                                            v-show="userInfo.history"
                                            style="text-align: center; padding-top: 15px"
                                        >
                                            <div>User history</div>
                                            <div
                                                v-for="item in userInfo.history"
                                                :key="item.id"
                                            >
                                                <a
                                                    @click="
                                                        getHistoryModel(item)
                                                    "
                                                    >{{ item.created_at }}</a
                                                >
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-dialog
                                    v-model="dialog_history"
                                    persistent
                                    max-width="600"
                                    @click:outside="dialog_history = false"
                                >
                                    <v-card v-if="history_item">
                                        <v-card-title class="headline">
                                            Editing history
                                        </v-card-title>
                                        <v-card-text>
                                            <div
                                                v-for="(item,
                                                index) in history_item.data"
                                                :key="index"
                                                style="text-align: center;"
                                            >
                                                <v-row>
                                                    <v-col cols="12">
                                                        <h3>
                                                            {{
                                                                index | getLabel
                                                            }}
                                                        </h3>
                                                    </v-col>
                                                    <v-col cols="5">
                                                        <template
                                                            v-if="
                                                                index ===
                                                                    'photo' ||
                                                                    index ===
                                                                        'verification_photo'
                                                            "
                                                        >
                                                            <v-img
                                                                max-height="200"
                                                                max-width="200"
                                                                :src="
                                                                    getHistoryImage(
                                                                        item.old,
                                                                    )
                                                                "
                                                            />
                                                        </template>
                                                        <template v-else>
                                                            {{ item.old }}
                                                        </template>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        -->
                                                    </v-col>
                                                    <v-col cols="5">
                                                        <template
                                                            v-if="
                                                                index ===
                                                                    'photo' ||
                                                                    index ===
                                                                        'verification_photo'
                                                            "
                                                        >
                                                            <v-img
                                                                max-height="200"
                                                                max-width="200"
                                                                :src="
                                                                    getHistoryImage(
                                                                        item.new,
                                                                    )
                                                                "
                                                            />
                                                        </template>
                                                        <template v-else>
                                                            {{ item.new }}
                                                        </template>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </v-container>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item v-if="form.role === 'investor'">
                        <div class="container">
                            <a-row>
                                <a-col :span="4">
                                    <p>Net Worth</p>
                                    <p>{{ form.net_worth || 'empty' }}</p>
                                </a-col>
                                <a-col :span="4">
                                    <p>Annual income</p>
                                    <p>{{ form.annual_income || 'empty' }}</p>
                                </a-col>
                                <a-col :span="4">
                                    <p>SSN</p>
                                    <p>{{ form.ssn || 'empty' }}</p>
                                </a-col>
                                <a-col :span="4">
                                    <p>Amount invest per year</p>
                                    <p>
                                        {{
                                            form.amount_invest_per_year ||
                                                'empty'
                                        }}
                                    </p>
                                </a-col>
                                <a-col :span="4">
                                    <v-btn
                                        color="error"
                                        @click="toggleResetFinances"
                                    >
                                        Reset finances
                                    </v-btn>
                                </a-col>
                            </a-row>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>
        <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
            style="width: 600px;"
        >
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
    </v-row>
</template>

<style>
.user_photo .v-responsive__sizer {
    padding-bottom: 58.4763% !important;
}

.img-fluid {
    max-width: 100%;
    width: auto !important;
}

.v-tabs-slider-wrapper {
    height: 0 !important;
}

.ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: 104px !important;
}

.editedField {
    border-color: green !important;
}

.ant-modal {
    width: 1000px !important;
}

.greenMailColor .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
    color: green;
}

.redMailColor .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
    color: red;
}

.checkbox_under_field .v-input--checkbox {
    margin-top: -20px !important;
}

.checkbox_under_field .v-input--selection-controls__ripple {
    height: 24px !important;
    margin-right: 0 !important;
}

.checkbox_under_field .theme--light {
    font-size: 14px !important;
}

.checkbox_under_field .v-input--selection-controls__ripple {
    display: none;
}

.checkbox_under_field {
    height: 33px;
}

.greenMailBorder fieldset {
    border-color: green !important;
}

.redMailBorder fieldset {
    border-color: red !important;
}

.checkbox_under_field {
    max-width: 145px !important;
}
::-webkit-scrollbar {
    width: 0;
}
</style>
